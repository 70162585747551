<template>
    <div class="vip-content">
        <div class="vip-title">
            <img src="@/assets/img/i-tit2.png" alt="">
            我的积分订单
        </div>
        <div class="vip-content-inner">


            <div class="order-det bill">
                <div class="row">
                    <div class="item">订单编号 : {{ orderInfo.orderNumber }}</div>
                    <div class="item">下单日期 : {{orderInfo.createTime}}</div>
                    <div class="item">订单状态 : {{ orderInfo.status==0?'处理中':orderInfo.status==1?'已确认':orderInfo.status==2?'待发货':orderInfo.status==3?'部分发货':orderInfo.status==4?'已发货':orderInfo.status==5?'部分收货':orderInfo.status==6?'已完成':orderInfo.status==7?'已取消':'-' }}</div>
                </div>
                <div class="row">
                    <div class="item">收货人 : {{ orderInfo.deliveryUsername }}</div>
                    <div class="item">收货手机号 : {{ orderInfo.deliveryPhone }}</div>
                    <div class="item">收货地址 : {{orderInfo.deliveryRegionCodeName + orderInfo.deliveryAddress }}</div>
                </div>

                <div class="g-table-wrap">
                    <table class="g-table">
                        <thead>
                        <tr>
                            <td>序号</td>
                            <td>商品名称</td>
                            <td>订单积分</td>
                            <td>数量</td>
                            <td>操作</td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item,index) in ordergoodsList" :key="index">
                            <td>{{ index + 1 }}</td>
                            <td>{{ item.name }}</td>
                            <td>{{ item.points }}</td>
                            <td>{{item.quantityPurchase}}</td>
                            <td style="text-align: center;">

                                <a v-if="item.status!=0&&item.status!=1&&item.status!=2&&item.status!=7&&item.status!=8&&item.status!=9" :href="`#/billDeliveryInfo?id=${item.orderId}&&goodsId=${item.integralGoodsId}`" class="blue2 cup">
                                    发货详情
                                </a>
                                <div v-else>发货详情</div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <div class="total-count">
                        <p>
                            <span class="label fwb">积分总额：</span>
                            <span class="con red">{{ orderInfo.integralPoint }}</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {orderIntegralgoodslist, orderIntegralDetail} from "@/api/Product";

export default {
    name: "goodsOrderDetail",
    data() {
        return {
            orderId:'', // 订单id
            orderInfo:'', // 订单信息
            ordergoodsList:[], // 订单商品列表

        }
    },
    mounted() {
        if(this.$route.query){
            console.log(this.$route.query)
            this.orderId=this.$route.query.id
            this.getorderDetail() //获取订单详情
            this.getorderGoods() //获取订单商品
        }
    },
    methods:{

        //    -------------接口请求
        //    获取订单详情
        getorderDetail(){
            let param={
                customerId:localStorage.getItem('customId'),
                id:this.orderId
            }
            orderIntegralDetail(param).then((res) => {
                this.loading=false
                if (res.data.code==200) {
                    // console.log('订单列表',res.data.data)
                    let data=res.data.data
                    data.amount=Number(data.amount)
                    data.freight=Number(data.freight)
                    data.createTime1=data.createTime
                    data.createTime=data.createTime.slice(0,10)
                    this.orderInfo=res.data.data
                    this.getfreight() //查询未支付订单自动取消

                }else{
                    // this.$message({
                    //     type: 'error',
                    //     message: res.data.message,
                    //     duration: 2000
                    // });
                }
            }).catch(err=>{
                this.loading=false
            })
        },
        //    获取订单商品
        getorderGoods(){
            let param={
                // customerId:localStorage.getItem('customId'),
                orderId:this.orderId
            }
            orderIntegralgoodslist(param).then((res) => {
                this.loading=false
                if (res.data.code==200) {
                    // console.log('订单列表',res.data.data)
                    res.data.data.forEach(i=>{
                        // i.describeText=this.$utils.showHtml(i.describeText)
                    })
                    this.ordergoodsList=res.data.data
                }else{
                    // this.$message({
                    //     type: 'error',
                    //     message: res.data.message,
                    //     duration: 2000
                    // });
                }
            })
        },

    },
}
</script>

<style scoped>

</style>
